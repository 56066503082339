<template>
  <div class="element-wrapper">
    <div class="element-box">
      <form id="formValidate" novalidate="true">
        <!-- Form Title -->
        <div class="element-info">
          <div class="element-info-with-icon">
            <div class="element-info-icon">
              <div class="os-icon os-icon-user"></div>
            </div>
            <div class="element-info-text">
              <h5 class="element-inner-header">Profile Settings</h5>
            </div>
          </div>
        </div>

        <!-- Email -->
        <div class="form-group">
          <label for=""> Email address</label>
          <input
            class="form-control"
            disabled
            v-model="this.user_data.email"
            data-error="Your email address is invalid"
            placeholder="Enter email"
            required="required"
            type="email"
          />
          <div
            class="help-block form-text with-errors form-control-feedback"
          ></div>
        </div>

        <!-- Old Password -->
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="">Old password</label>
              <input
                class="form-control"
                data-minlength="6"
                placeholder="Password"
                required="required"
                type="password"
                v-model="v$.user_password_form.old_password.$model"
              />
            </div>
          </div>
        </div>

        <!-- New Password and Confirm Password -->
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">New password</label>
              <input
                class="form-control"
                data-minlength="6"
                placeholder="Password"
                required="required"
                type="password"
                v-model="v$.user_password_form.password.$model"
              />
              <div
                class="help-block form-text text-muted form-control-feedback"
              >
                Minimum of 6 characters
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="">Confirm Password</label>
              <input
                class="form-control"
                data-match-error="Passwords don't match"
                placeholder="Confirm Password"
                required="required"
                type="password"
                v-model="v$.user_password_form.confirm_password.$model"
              />
              <div
                class="help-block form-text with-errors form-control-feedback"
              ></div>
            </div>
          </div>

          <!-- Update Password -->
          <div
            class="form-buttons-w"
            style="border-top:none; padding-top: 0rem; padding-bottom: 1rem;"
          >
            <button
              class="btn btn-primary"
              :disabled="v$.user_password_form.$invalid"
              type="button"
              @click="updatePassword()"
            >
              Update Password
            </button>
          </div>
        </div>

        <!-- <div class="form-group">
          <label for="">City</label><select class="form-control">
            <option value="Redmond"> Redmond </option>
            <option value="California"> California </option>
            <option value="Boston"> Boston </option>
            <option value="Texas"> Texas </option>
            <option value="Colorado"> Colorado </option>
          </select>
        </div> -->

        <fieldset class="form-group">
          <legend><span>User Info</span></legend>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for=""> First Name</label
                ><input
                  class="form-control"
                  data-error="Please input your First Name"
                  placeholder="First Name"
                  required="required"
                  v-model="v$.user_detail_form.username.$model"
                  type="text"
                />
                <div
                  class="help-block form-text with-errors form-control-feedback"
                ></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="">Last Name</label
                ><input
                  class="form-control"
                  data-error="Please input your Last Name"
                  placeholder="Last Name"
                  required="required"
                  v-model="v$.user_detail_form.lastname.$model"
                  type="text"
                />
                <div
                  class="help-block form-text with-errors form-control-feedback"
                ></div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for=""> Date of Birth</label><input class="single-daterange form-control"
                  placeholder="Date of birth" type="text" value="04/12/1978">
              </div>
            </div>
          </div> -->
        </fieldset>
        <!-- <div class="form-check">
          <label class="form-check-label"><input class="form-check-input" type="checkbox">I agree to terms and conditions</label>
        </div> -->
        <div
          class="form-buttons-w"
          style="margin-top:0rem; border:none; padding-top:0px"
        >
          <button
            class="btn btn-primary"
            :disabled="v$.user_detail_form.$invalid"
            type="button"
            @click="updateUser()"
          >
            Update User Details
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import httpClient from "../../../../service/httpClient";
import { useToast } from "vue-toastification";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      user_detail_form: {
        username: "",
        lastname: "",
        position: "-"
      },

      user_password_form: {
        old_password: "",
        password: "",
        confirm_password: ""
      },

      user_data: {}
    };
  },

  created() {
    this.getData();
  },

  validations() {
    return {
      email: "",
      user_detail_form: {
        username: { required },
        lastname: { required }
        // position: { required },
      },

      user_password_form: {
        old_password: { required },
        password: { required, min: minLength(8) },
        confirm_password: { required, min: minLength(8) }
      }
    };
  },

  methods: {
    getData() {
      const id = localStorage.getItem("id");
      httpClient.getData("account/" + id + "/").then(response => {
        this.user_data = response;
        this.user_detail_form.username = this.user_data.username;
        this.user_detail_form.lastname = this.user_data.lastname;
      });
    },

    updateUser() {
      const requestdata = JSON.stringify(this.user_detail_form);

      httpClient
        .put(
          "account/update-delete/" + this.user_data.email + "/",
          "",
          requestdata
        )
        .then(response => {
          this.user_detail_form.username = this.user_detail_form.lastname = "";
          this.getData();
        })
        .catch(error => {});
    },

    updatePassword() {
      const requestdata = JSON.stringify(this.user_password_form);

      httpClient
        .put(
          "account/change_password/" + this.user_data.email + "/",
          "",
          requestdata,
          true,
          true,
          false
        )
        .then(response => {
          if (response == "error") {
            return;
          }

          if (response.response.status == 400) {
            if (response.response.data.old_password)
              useToast().error(
                response.response.data.old_password.old_password
              );
            else if (response.response.data.password) {
              useToast().error(response.response.data.password[0]);
            }
          }
          this.user_password_form = {};
          this.getData();
        });
    }
  }
};
</script>
